<template>
<main>
  <CategoryBanner/>
  <div class="contact">
    <section>
      <h3>Contactez-nous par téléphone</h3>
      <a aria-label="Téléphone La Famille Boutrais" href="tel:+0299894816"><p>02 99 89 48 16</p></a>
    </section>
    <section>
      <h3>Contactez-nous par email</h3>
      <form method="post" @submit.pr.prevent="send">
        <div class="form__row">
          <div class="form__row__item">
            <input
                class="input"
                type="text"
                v-model="user.name"
                placeholder="Nom"
                id="help-name"
                required
            />
            <label for="help-name">Votre nom</label>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row__item">
            <input
                class="input"
                type="text"
                v-model="user.email"
                placeholder="email"
                id="help-email"
                required
            />
            <label for="help-email">Votre email</label>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row__item">
            <input
                class="input"
                type="text"
                v-model="contactForm.subject"
                placeholder="Objet de votre demande"
                id="help-object"
                required
            />
            <label for="help-object">Objet de la demande</label>
          </div>
        </div>
        <div class="form__row">
          <div class="form__row__item">
					<textarea
              class="input"
              type="text"
              v-model="contactForm.body"
              placeholder="votre Message"
              id="help-txt"
              required
          />
            <label for="help-txt">Message</label>
          </div>
        </div>
        <button aria-label="send" class="button full" type="submit">envoyer</button>
      </form>
      <div id="response" style="display: none">Votre message a bien été envoyé</div>
    </section>
  </div>
</main>
</template>

<script>
import {mapGetters} from "vuex";
import {getAPI} from "@/services/axios-api";
const CategoryBanner = () => import ("@/components/CategoryBanner.vue")
export default {
  name: "ContactUs",
  metaInfo: {
    title: 'Nous contacter',
  },
  components: {CategoryBanner},
  beforeMount() {
    this.$store.commit('category/setCategory', 20)
  },
  computed: {
    ...mapGetters('auth', ['getUserId']),
  },
  data() {
    return {
      user: [],
      contactForm: {
        subject: null,
        body: null,
      },
    }
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async getUser() {
      await getAPI
          .get('account/profile/' + this.getUserId + '/')
          .then(response => this.user = response.data)
          .catch(error => console.log(error.data))
    },
    async send() {
      const formData = this.contactForm;
      formData['name'] = this.user.name;
      formData['email'] = this.user.email;
      await getAPI
          .post('account/profile/send-mail/', formData)
          .finally(
              window.document.getElementById('response').style.display = 'Block'
          )
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  margin: 50px auto;
}

a {
  color: $Brown;
  text-decoration: none;
  @include flex($direction: row, $gap: 10px);
  width: 100%;
  //max-width: 110px;
  margin: 0 auto;
}

form {
  @include flex(flex-start, flex-start, column, $gap: 10px);
  width: 100%;
  max-width: 650px;
  margin: 0 auto;

  .button {
    text-align: center;
    @include flex(flex-start, flex-start, column, $gap: 10px);
    width: 100%;
    max-width: 135px;
    margin: 10px auto;
  }

  .form__row {
    @include flex(flex-start, flex-start, $gap: 10px);
    width: 100%;

    @media screen and (max-width: 575.99px) {
      flex-direction: column;
    }

    &__item {
      @include flex(flex-start, flex-start, column-reverse, $gap: 3px);
      width: 100%;

      .input[required] ~ label::after {
        content: '*';
        color: #e33131;
        font-weight: bold;
        margin-left: 2px;
      }

      label {
        color: $Black;
        font-weight: 600;
        font-size: 14px;
        position: relative;
      }

      .input {
        background-color: #F9F9F9;
        color: $Black;

        &:not([type="radio"]) {
          padding: 10px 20px;
          width: 100%;
        }

        &::placeholder, &[type="date"] {
          text-transform: uppercase;
          color: #BDBDBD;
        }
      }
    }
  }

  a {
    color: $Brown;
  }
}

.contact {
  width: 90%;
  margin: 50px auto 0;

  h3 {
    margin-bottom: 25px;
    text-align: center;
  }
}

#response {
  @include color('background-color', $Green, .2);
  color: $Green;
  width: 100%;
  max-width: 650px;
  padding: 5px 15px;
  margin-top: 20px;
  border-radius: 5px;
  font-weight: 600;
}

</style>